import React from "react"
import { Panel } from 'rsuite';
import { motion } from "framer-motion"
 
function Projects(props) {
  return (
    <div>
      <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delay: 0,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} 
        className="projectsHero">
        <div className="mainCopyArea">
            <p>👋&nbsp;&nbsp;Hi, I'm <a href="/About" className="textlink">Joost</a>.</p>
            <p>I’m a product designer and creative technologist. 
            <br/>
            Currently shaping the future of payments at <a className="textlink" href="https://mollie.com" target="_blank">Mollie</a></p>
        </div>
      </motion.div>
      <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delay: .1,
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} 
        className="projectsPanel">
        
        <Panel shaded bodyFill className="panel">
        <a href="/mollie">
          <div className="panelImageContainer">
            <img src="./img/Mollie.jpg" height="240" alt="An iPhone showing transactions in the app Mollie"/>
          </div>
          <Panel>
            <p><strong>Mollie</strong></p>
            <p>
              Effortless payments.
            </p>
          </Panel>
        </a>
        </Panel>

        <Panel shaded bodyFill className="panel">
        <a href="/Videoland">
          <div className="panelImageContainer">
            <img src="./img/Videoland.jpeg" height="240" alt="Several mobile devices showing Videoland websites and apps"/>
          </div>
          <Panel>
            <p><strong>Videoland</strong></p>
            <p>
              Weet wat speelt.
            </p>
          </Panel>
        </a>
        </Panel>

        <Panel shaded bodyFill className="panel">
          <a href="/Workplaces">
            <div className="panelImageContainer">
              <img src="./img/Workplaces.jpeg" height="240" alt="An iPhone showing the app Workplaces" />
            </div>
            <Panel>
              <p><strong>Workplaces</strong></p>
              <p>
                Find a place to work or study.
              </p>
            </Panel>
          </a>
        </Panel>

        <Panel shaded bodyFill className="panel">
          <a href="/Lexis">
            <div className="panelImageContainer">
              <img src="./img/Lexis.gif" height="240" alt="An iPhone showing transactions in the app Mollie"/>
            </div>
            <Panel>
              <p><strong>Lexis</strong></p>
              <p>
                Mindfulness through photography.
              </p>
            </Panel>
          </a>
        </Panel>

        <Panel shaded bodyFill className="panel">
          <a href="/Puma">  
            <div className="panelImageContainer">
              <img src="./img/Puma.jpeg" height="240" alt="Several mobile devices showing Videoland websites and apps"/>
            </div>
            <Panel>
              <p><strong>Puma</strong></p>
              <p>
                AR running visualizations.
              </p>
            </Panel>
          </a>
        </Panel>

        <Panel shaded bodyFill className="panel">
          <a href="/Unicef">
            <div className="panelImageContainer">
              <img src="./img/Unicef VR.gif" height="240" alt="An image of the app Workplaces" />
            </div>
            <Panel>
              <p><strong>Unicef</strong></p>
              <p>
                VR experience.
              </p>
            </Panel>
          </a>
        </Panel>
      </motion.div>
    </div>
  )
}
 
export default Projects;        