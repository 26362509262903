import React from "react";
import { Panel } from 'rsuite';
import { motion } from "framer-motion";

function Writing(props) {
    return (
      <div className="Writing">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <div className="mainCopyArea">
          <h1>Writing</h1>
          <p>
          Through sharing my personal thoughts and opinions in the form of writing I think my design skills and thought processes can grow. 
          </p>
        </div>

        <div className="articlesList">
            <h3>2020</h3>
            <div className="projectsPanel">
                <Panel shaded bodyFill className="panel">
                <a href="https://blog.prototypr.io/redesigning-videolands-landing-page-a-ux-case-study-6ab920ccfb0d">
                <div className="panelImageContainer">
                    <img src="./img/vl-landing.png" height="240" alt="A flat illustration of a woman using the Videoland app on a tablet"/>
                </div>
                <Panel>
                    <p><strong>Redesigning Videoland's Landing page</strong></p>
                    <p>
                    A UX case study
                    </p>
                </Panel>
                </a>
                </Panel>
                <Panel shaded bodyFill className="panel">
                <a href="https://blog.prototypr.io/what-should-electric-cars-sound-like-21b3aae782c8">
                <div className="panelImageContainer">
                    <img src="./img/evs.jpg" height="240" alt="An electric car on the road"/>
                </div>
                <Panel>
                    <p><strong>What should electric cars sound like?</strong></p>
                    <p>
                        On how I designed the sound of an electric car and the philosophy behind it
                    </p>
                </Panel>
                </a>
                </Panel>
            </div>
            <h3>2019</h3>
            <div className="projectsPanel">
                    <Panel shaded bodyFill className="panel">
                    <a href="https://www.ibc.org/trends/spotlight-on-ux-designers/3857.article">
                        <div className="panelImageContainer">
                        <img src="./img/joost.jpg" height="240" alt="Portrait of Joost"/>
                        </div>
                        <Panel>
                        <p><strong>Interview by IBC</strong></p>
                        <p>
                            For 'Spotlight on: UX Designers'
                        </p>
                        </Panel>
                    </a>
                    </Panel>
            </div>
            <h3>2018</h3>
                <div className="projectsPanel">
                    <Panel shaded bodyFill className="panel">
                    <a href="https://blog.prototypr.io/the-importance-of-motion-for-ux-design-7710edcdae3a">
                        <div className="panelImageContainer">
                        <img src="./img/motion.png" height="240" alt="An iPhone showing the app Workplaces" />
                        </div>
                        <Panel>
                        <p><strong>The importance of motion for UX design</strong></p>
                        <p>
                            On the how and why of motion for UX
                        </p>
                        </Panel>
                    </a>
                    </Panel>
                </div>
        </div>
        </motion.div>
      </div>
    )
  }
   
  export default Writing;