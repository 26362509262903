import React from "react"
import { motion } from "framer-motion"

function Art(props) {
    return (
      <div className="Art">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <div className="mainCopyArea">
          <h1>Art</h1>
          <p>
              Whenever I have time I like to experiment with creating abstract visuals in 2D as well as 3D. You can find the collection of things I've created over the years here.
          </p>
        </div>
        <div className="artGallery">
            <img src="./img/Mesh One.png" alt=""/>
            <img src="./img/Mesh Two.png" alt=""/>
            <img src="./img/Mesh Three.png" alt=""/>
            <img src="./img/Donuts.jpg" alt=""/>

        </div>
        </motion.div>
      </div>
    )
  }
   
  export default Art;