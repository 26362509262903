import React from "react"
import { motion } from "framer-motion"

function Puma(props) {
    return (
      <div className="Puma">
          <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <section className="hero">
            <img src="./img/pumaLogo.png" alt="Puma Logo" className="caseHeroImage"/>
            <h1>An AR application to visualise your run</h1>
                <ul className="projectMeta">
                    <li>
                        <p><strong>Date</strong></p>
                        <p>July - Novemer 2017</p>
                    </li>
                    <li>
                        <p><strong>Client</strong></p>
                        <p>Puma</p>
                    </li>
                    <li>
                        <p><strong>Role</strong></p>
                        <p>Visual/Interaction Designer</p>
                    </li>
                    <li>
                        <p><strong>Team</strong></p>
                        <p>Raimo van der Klein
                            Michael Plein
                            Rick Veenstra
                            Kevin Visser
                            Bas van Rijn
                            Erik van Langen
                        </p>
                    </li>
                </ul>
        </section>
        <section className="projectBackground">
            <h2>Project Background</h2>
            <p>At Triple we got approached by Puma to make a POC for an AR application that would visualise running routes of the users of their already existing PumaTrac app.</p>
            <img src="./img/Puma.jpeg" alt="The 3D model for the Puma AR app" className="mockup rounded" width="100%" height="100%"/>
            <p>Because of my previous experience with designing for VR and AR I was assigned as the designer on this case, creating the visual style for the application. Aesthetically the main aim was a futuristic look, since the POC would be shown at a conference about the technological future of Puma.
            </p>
        </section>
        <section id="process">
            <h2>Process</h2>
            <p>
                Working togehter with the Unity developers we created a futuristic look with red and black as main colours. The user's position is represented by a beacon that has a display floating above it showing the user's statistics, such as pace and distance travelled. During this iterative process we came to a style that was most in line with the briefing, this version was eventually handed over to Puma to display at their conference.
            </p>
            <div className="mockupImages">
                <img src="./img/device.png" alt="The UI of tha accompanying app" className="mockup" width="250px" height="100%"/>
                <img src="./img/arkit.jpg" alt="An AR representation of a run" className="mockup rounded" width="350px" height="100%"/>
            </div>
        </section>
        </motion.div>
        </div>
    )
  }
   
  export default Puma;