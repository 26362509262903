import './rsuite-dark-custom.css'
import './App.css';
import Header from './Header.jsx';
import Projects from './Projects.jsx';
import About from './About.jsx';
import Footer from './Footer.jsx';
import Videoland from './Videoland.jsx';
import Mollie from './Mollie.jsx';
import Workplaces from './Workplaces';
import Puma from './Puma';
import Unicef from './Unicef';
import Writing from './Writing.jsx';
import Art from './Art.jsx';
import Music from './Music.jsx';
import Lexis from './Lexis.jsx';
import { BrowserRouter, Route, Link } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        
        <Route exact path="/" component={Projects} />
        <Route path="/about" component={About} />
        <Route path="/videoland" component={Videoland} />
        <Route path="/mollie" component={Mollie} />
        <Route path="/workplaces" component={Workplaces} />
        <Route path="/puma" component={Puma} />
        <Route path="/unicef" component={Unicef} />
        <Route path="/lexis" component={Lexis} />
        <Route path="/writing" component={Writing} />
        <Route path="/art" component={Art} />
        <Route path="/music" component={Music} />

        <Footer />
      </div>
      </BrowserRouter>
  );
}

export default App;