import React from "react";
import { Popover, Whisper, Button, ButtonToolbar } from 'rsuite';

const speaker = (
  <Popover>
    <p>
      This portfolio was designed in <a href="https://figma.com" className="textlink">Figma</a>, built with <a href="https://reactjs.org/" className="textlink">React</a>, <a href="https://rsuitejs.com/" className="textlink">React Suite</a>, <a href="https://styled-components.com/" className="textlink">styled components</a> and <a href="https://www.framer.com/motion/" className="textlink">Framer Motion</a> and deployed through <a href="https://www.netlify.com/" className="textlink">Netlify</a> during <a href="https://en.wikipedia.org/wiki/COVID-19_pandemic_in_the_Netherlands" className="textlink">lockdown</a>. 
    </p>
  </Popover>
);

function Footer(props) {
    return (
      <div className="Footer">
        <hr class="rounded"></hr>
        <div className="footerContainer">

        © {(new Date().getFullYear())}
        <Button appearance="subtle">
            <a href="mailto: hi@joostreus.com">Email</a>
        </Button>
        <Button appearance="subtle">
            <a href="https://twitter.com/joostreusdj">Twitter</a>
        </Button> 
          <Whisper placement="top" trigger="click" speaker={speaker}>
            <Button appearance="subtle">About this portfolio</Button>
          </Whisper>
        </div>
        <img className="sig" src="./img/sig.gif" alt="That's me." width="100px"/>
      </div>
    )
  }
   
  export default Footer;