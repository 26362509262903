import React from "react"
import { motion } from "framer-motion"

function Lexis(props) {
    return (
      <div className="Puma">
          <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <section className="hero">
            <img src="./img/Lexis.gif" alt="A 3D mockup of a phone with the Lexis app open" className="caseHeroImage"/>
            <h1>Mindfulness and a shift of perspective through digital photography</h1>
                <ul className="projectMeta">
                    <li>
                        <p><strong>Date</strong></p>
                        <p>February - June 2018</p>
                    </li>
                    <li>
                        <p><strong>Client</strong></p>
                        <p>Triple, Hogeschool van Amsterdam</p>
                    </li>
                    <li>
                        <p><strong>Role</strong></p>
                        <p>Concept Developer, UX Researcher, UX designer, Visual Designer</p>
                    </li>
                    <li>
                        <p><strong>Supervisors</strong></p>
                        <p> Lex Koetzier
                            Allard Roeterink
                        </p>
                    </li>
                </ul>
        </section>
        <section className="projectBackground">
            <h2>Project Background</h2>
            <p>Graduating my Bachelor studies in Communication & Multimedia Design I got offered a project by Triple's Lead Innovation Lex Koetzier.
            </p>
            <p>
            The goal was to create a different, more mindfulness-inspired approach to digital photography and photosharing. In an industry that is dominated by selfies and "influencers", we wanted to create something that made people look around, evaluate and realise the value of spaces, people and things.
            </p>
            <div className="callout">
                "Blessed are they who see beautiful things in humble places where other people see nothing"
                <br />
                -
                <br />
                Camille Pissaro
            </div>
        </section>
        <section id="process">
            <h2>Process</h2>
            <img src="./img/lexistimeline.png" alt="The timeline that shows the planning of this project" className="mockup" width="100%" height="100%"/>
            <p>
            I started by defining a planning and strategy, evaluating the methods and tools I wanted to use during this project.
            </p>
            <p>
            Devided into 4 different stages, namely "Introduction/Defining", "Research", "Concepting", "Designing" and "Testing & Validating" (as a parallel project stage).
            </p>
            <p>
            I further subdevided these stages to differentiate, for example, between desk and field research.
            </p>
            <p>Creating a planning in this way was a great way to start thinking about the way I wanted to tackle this project and what tools I was going to use at what stages in my project.
            </p>
            <p>
            I used Trello as a tool to create an interactive version of the planning including deadlines and attachments, with color coding to keep track of the planning.
            </p>
            <img src="./img/lexistrello.png" alt="Screenshot of the Trello board used for planning the project" className="mockup" width="100%" height="100%"/>
            <p>
            In the first stage of the project there was a lot of brainstorming and defining the scope of the project — how to create a well-functioning prototype from the abstract idea of making people more mindfull for their surroundings through digital photography? This is defined in the Design Challenge:
            </p>
            <div className="callout">
            "How can we, using digital photography as a medium, make the user look at their surroundings more consciously, to create a moment of mindfulness?"
            </div>
            <p>At the same time it was important to at this stage of the project to map out relevant stakeholders and set up a Statement of Requirements. 
            </p>
            <p>
            Also mapping out the apps that were already out there in a Competitor Analysis was part of this research stage.
            </p>
            <img src="./img/lexisconcurrentie.png" alt="Screenshot of the Trello board used for planning the project" className="mockup" width="100%" height="100%"/>
            <p>In the next stage of the project I started setting up a test and interviewing some potential users over the internet. With the information gathered from these interviews I created several Personae, model users with specific needs and wants.
            </p>
            <p>
            To gain a deeper understanding of what could motivate users for the use of this application I created Empathy Maps per Persona, to map out the things they hear, see and think regarding the context of this app. The Empathy Maps were then used to base the Customer Journeys on; the way users are likely to come in contact with - and use the app.
            </p>
            <img src="./img/lexisEM.png" alt="Empathy Maps of the different personae to map out what they experience around them" className="mockup" width="100%" height="100%"/>
            <img src="./img/lexisCJ.png" alt="The mapped out Customer Journeys per personae" className="mockup" width="100%" height="100%"/>
            <p>
            After gaining some deeper insights into what drives the user and gaining more empathy and understanding of their way of possibly interacting with the app, I started sketching out wireframes on paper, that I quite quickly moved into digitally into Sketch to create a functional prototype.
            </p>
            <p>
            Moving quickly to this prototype was very important, because it allowed me to start testing with users in the target group and see how they experience the concept, as well as test the usability of the interface.
            </p>
            <img src="./img/lexisWF.png" alt="Wireframes of the Lexis interface in Sketch" className="mockup" width="100%" height="100%"/>
            <p>
            After testing this with users in the User Test Lab at the Amsterdam University of Applied Sciences, there was the possibility to quickly iterate based on learnings from this session.
            </p>
            <p>The most important points were how the onboarding was interperted and whehter people understood the concept, where improvements could be made to make it more clear. 
            </p>
            <p>
            I took these learnings into consideration when designing the hi-fi prototype of the next step, that would also allow to test the visual style and branding of the app.
            </p>
            <img src="./img/lexisFinal.png" alt="The final interface of the Lexis app" className="mockup" width="100%" height="100%"/>
        </section>
        </motion.div>
        </div>
    )
  }
   
  export default Lexis;