import React from "react"
import { Button, ButtonToolbar } from 'rsuite';
import { motion } from "framer-motion"

function Unicef(props) {
    return (
      <div className="Unicef">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <section className="hero">
            <img src="./img/unicefLogo.png" alt="Unicef Logo" className="caseHeroImage"/>
            <h1>A playful VR experience to connect with millennials
            </h1>
                <ul className="projectMeta">
                    <li>
                        <p><strong>Date</strong></p>
                        <p>February - July 2017</p>
                    </li>
                    <li>
                        <p><strong>Client</strong></p>
                        <p>Unicef</p>
                    </li>
                    <li>
                        <p><strong>Role</strong></p>
                        <p>Unity Developer, UX Researcher, concept- interaction- sound designer</p>
                    </li>
                    <li>
                        <p><strong>Team</strong></p>
                        <p>Renier van Rooij
                            Ronny Rook
                            Sanne Bovenberg
                            Youssra Oulad
                        </p>
                    </li>
                </ul>
        </section>
        </motion.div>
        <section className="projectBackground">
            <h2>Project Background</h2>
            <p>
            In the second half year of academic year 2016/2017 the Virtual Reality Experience Minor at the University of Applied Sciences took place. With an interdisciplinary team consisting of students of Multimedia Design, ICT, Civil Engineering and Fashion Branding, Unicef asked us to create a VR application that would gather more awareness among (young) millenials.    
            </p>
            <img src="./img/hands.gif" alt="Using leap motion to read inpit from hand movement" className="mockup rounded" width="100%" height="100%"/>
        </section>
        <section id="process">
            <h2>Process</h2>
            <p>
            We developed an application in Unity for the Oculus Rift, using a Leap Motion module as input device to scan the user's hands and display them in VR. We decided to go for this combination because it would be more hands-on (literally) and easier to learn for the user.
            </p>
            <p>
            We created a world in a low-poly art style around the topic of education for refugees. The final product features several mini games besides a general objective.
            </p>
                <img src="./img/unicefworld.png" alt="The 3D world that we created in Unity for Unicef" className="mockup rounded" width="100%" height="100%"/>
            <p>We worked closely with Unicef to ensure that we stayed close to their goals and expectations, while also testing on the target audience to make sure the concept whas comprehensible and to optimise the usibility of our application.
            </p>
            <p>
            After finishing the project I wrote a quite detailed article discussing the way we worked and the things we learned along the way.
            </p>
            <Button className="inPage" size="lg" block href="https://blog.prototypr.io/how-we-created-a-vr-app-for-unicef-cf2c171f31f3">Read full article</Button>
        </section>
      </div>
    )
  }
   
  export default Unicef;