import React from "react"
import { motion } from "framer-motion"


function Mollie(props) {
    return (
      <div className="Mollie">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delay: 0,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} 
        className="mollieHero">
          <section className="hero">
            <img src="./img/mollielogo.png" alt="Screenshots of Mollie's dashboard" className="caseHeroImage"/>
            <p className="medium">In the early summer of 2021, I joined <a href="https://mollie.com" className="textlink">Mollie</a> as a Product Designer. 
            <br/>
            The work that I will be doing will be showcased here later.</p>
          </section>
        </motion.div>
      </div>
    )
  }
   
  export default Mollie;