import React from "react"
import { Button } from 'rsuite';
import { motion } from "framer-motion"

function Videoland(props) {
    return (
      <div className="Videolad">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <section className="hero">
            <img src="./img/Videoland.jpeg" alt="Videoland apps in devices" className="caseHeroImage"/>
            <h1>Shaping a better UX for the biggest Dutch streaming
                platform</h1>
                <ul className="projectMeta">
                    <li>
                        <p><strong>Date</strong></p>
                        <p>September 2018 - May 2021</p>
                    </li>
                    <li>
                        <p><strong>Client</strong></p>
                        <p>RTL Nederland</p>
                    </li>
                    <li>
                        <p><strong>Role</strong></p>
                        <p>UI/UX Designer</p>
                    </li>
                    <li>
                        <p><strong>Team</strong></p>
                        <p>App, Growth/Stability, Onboarding/Desire</p>
                    </li>
                </ul>
        </section>
        {/* Case nav, need to fix this */}
        {/* <section className="projectMeta">
                <Button size="lg" href="#appTeam">
                    Apps
                </Button>
                <Button size="lg"  href="#landingPages">
                    Landing
                </Button>
                <Button size="lg"  href="#rebranding">
                   Rebrand
                </Button>
                <Button size="lg"  href="#tiering">
                   Tiering
                </Button>
                <Button size="lg"  href="#designSystem">
                   Designsys
                </Button>
        </section> */}
        <section className="productBackground">
            <h2>Product Background</h2>
            <p>Videoland is the second biggest media streaming service in the Netherlands (after Netflix), and the biggest originally Dutch streaming service, with currently over 1 million monthly paid subscriptions. As a daughtercompany of RTL Nederland, it's offices are located at the Media Park in Hilversum.</p>
        </section>
        <section id="appTeam">
            <h2>App Team</h2>
            <p>In September of 2018 I joined the Videoland Apps team at RTL Nederland, to work on the applications for iOS, Android, tvOS, Android TV and SmartTV. I was assigned to work on the visual and interaction design of various user stories.</p>
            <img src="./img/rtlkantoor.jpeg" alt="RTL's office at the Media Park" className="caseHeroImage"/>
            <p>I worked closely with two other UX designers, PO, tester, UX researcher and several developers. For the design we worked with a component library that contained the main design elements that the app consists out of - looseley based on the Atomic Design philosophy. Using Sketch and Principle we designed and prototyped new interface elements and interactions and using Zeplin we handed them off to the developers. We worked in Scrum and used Jira for project management. <br/>
            <br/>
            During my time on the app team, among other things, a big project was the restructuring of the main navigation elements in the mobile apps. For this, we worked together closely with the UX Researcher on the team.</p>
            <div className="mockupImages">
                <img src="./img/VLappOld.png" alt="The old Videoland iPhone app" className="mockup" width="266px" height="100%"/>
                <img src="./img/VLappNew.png" alt="The new Videoland iPhone app" className="mockup" width="268px" height="100%"/>
            </div>
        </section>
        <section id="landingPages">
            <h2>Landing Pages</h2>
            <p>After half a year on the Apps team, I joined the team working on onboarding and landing pages as the main designer. The landing pages are the pages that prospect customers land on before they subscribe (through the onboarding flow) for the product. The main landing page, videoland.com, has an average of ~ 1 milion unique visitors per month. </p>
            <br/>
            <p>After working on various A/B tests to incrementally improve this page, in October 2019 together with our Lead CRO Lucas Vos, I set up a project to drastically redesign videoland.com.</p>
            <p>The goal was to create a page that aligns better with the needs and wants of prospect customers, designed in a mobile-first manner with short copy that conveys the message and speaks to the target audience.</p>
            <br/>
            <img src="./img/landingpages.png" alt="Mockups of the redesigned landing pages of Videoland" className="caseHeroImage"/>
            <br/>
            <p>
            In April 2020, we launched the new landing page. I wrote an article on the process and learnings of co-creating, prioritizing, designing, optimizing and testing this page.</p>
            <Button className="inPage" size="lg" block href="https://blog.prototypr.io/redesigning-videolands-landing-page-a-ux-case-study-6ab920ccfb0d?gi=4b5ed5ee4fe9">Read full article</Button>
        </section>
        <section id="rebranding">
            <h2>Rebranding</h2>
            <img src="./img/rebranding.jpeg" alt="Mockups of the rebranding of Videoland" className="caseHeroImage"/>
            <p>In August of 2019 a project was launched at Videoland to rebrand the product. At the time, there wasn't a very clear visual language and coherent style (there were several different primary colors floating around, that were isolated from old designs and logos) which did not make for a strong brand and consistent experience across platforms. We worked together with branding agency Dentsu Achtung (then Achtung McGarryBowen)﻿ to create a new visual identity. I was involved with the development of the style along with the other designers at Videoland. </p>
            <br/>
            <p>After the branding was final, the work came of implementing it in a consistent way into the product. Key here was consistency across different channels, from apps and web to smart TV and marketing materials. I was mostly involved in creating the designs and guiding and overseeing the implementation for the web touchpoints, inlcuding the landing pages, onboarding and web platform.</p>
        </section>
        <section id="tiering">
            <h2>Subscription Tiers</h2>
            <img src="./img/tiering.png" alt="A screenshot of a page where you can select your subscription tier on Videoland" className="caseHeroImage"/>
            <p>In the summer of 2020 there were advanced plans at Videoland to introduce three different subscription tiers, which provide different features for different prices. I helped define the names of the tiers, which eventually became Basis (Dutch for "basic"), Plus, and Premium. After establishing the features I worked on wireframing and designing the flow and the UI elements that guide the user through selecting a tier that fits the best for them, having all the information and being able to make their descision before they fully onboard.</p>
            <br/>
            <p>Another important part of introducing these different subscription tiers occurs lateron in the customer journey. Namely, the possibility for the customer to switch tiers at any point in their billing cycle, providing a supporting interface that shows them exactly how and when their changes will affect their experience and billing. In order to make sure this was clear enough to the user I worked on mapping out the flows, creating interactive prototypes, testing these with users and iterating UI elements and copy based on the findings.</p>
        </section>
        <section id="designSystem">
            <h2>Design System</h2>
            <img src="./img/designsystem.jpg" alt="A screenshot of a page where you can select your subscription tier on Videoland" className="caseHeroImage"/>
            <p>Since the day that I started working full-time at Videoland, I set up a project to create a unified design system for web. Along with this I've worked on introducing design versioning and developer handoff using Abstract. </p>
            <br/>
            <p>This made for a single source of truth, better collaboration on designs and more transparency about what is being worked on. Next to master files, component libraries and branches in Abstract, I created an accompanying style guide in ZeroHeight. This way, our design principles and rationale behind components, elements and styles is more clear and can easily be communicated.</p>
        </section>
        </motion.div>
      </div>
    )
  }
   
  export default Videoland;