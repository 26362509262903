import React from "react"
import { Panel, PanelGroup } from 'rsuite';
import { motion } from "framer-motion"
 
function About(props) {
  return (
    <div className="About">
      <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} 
        className="aboutHero">
      <div className="aboutPortrait">
        <img src="./img/portrait.jpg" alt="" width="15px" height="150px"/>
      </div>
      <section className="aboutCopy">
        <h1>
        My name is Joost.
        </h1>
        <br />
        <br />
        <p className="subline">
        What makes me happy are digital products that work well, make complex concepts simple to execute and add value to the life of the user, all the while looking as clean as possible.
        </p>
      </section>
      </motion.div>
      <div className="accordion">
        <div className="accordionItem">
          <Panel header="About Me" collapsible shaded>
            <p>
            I graduated with a major in Communication & Multimedia Design and a minor in VR/AR at <a href="https://www.cmd-amsterdam.nl/english/" className="textlink">CMD Amsterdam</a>. I'm currently a Product Designer at <a href="https://mollie.com/" className="textlink">Mollie</a>.</p>
            <p>I also like to write, play and produce <a href="/Music" className="textlink">music</a>. 
            </p>
            <p>In 2014 I founded my own record label, Sphinx Recordings.</p>
            <p>I like to share my thoughts, for that you could check out the <a href="/Writing" className="textlink">Writing</a>
            page.
            </p>
          </Panel>
        </div>
        <div className="accordionItem">
          <Panel header="Experience" collapsible shaded>
            <p> 
              <strong>2021 - current</strong><br/>
              Product Designer <br/>
              <a href="https://mollie.com/" className="textlink">Mollie</a>
            </p>
            <p> 
              <strong>2019 - 2021</strong><br/>
              UI/UX Designer <br/>
              <a href="https://videoland.com/nl/" className="textlink">Videoland</a>
            </p>
            <p>
              <strong>2016 - 2019</strong><br/>
              UI/UX Designer <br/>
              <a href="https://wearetriple.com/" className="textlink">Triple</a>
            </p>
            <p>
              <strong>2015 - 2016</strong><br/>
              Freelance designer & developer <br/>
              <span className="subline">Vaxel</span>
            </p>
            <p>
              <strong>2015</strong><br/>
              Junior Front-End Developer <br/>
              <a href="https://dashmote.com/" className="textlink">Dashmote</a> 
            </p>
          </Panel>
        </div>
        <div className="accordionItem">
          <Panel header="Education" collapsible shaded>
            <p>
            <strong>2014-2018</strong><br/>
            BSc. Communication & Multimedia Design <br/>
            <span className="subline">Hogeschool van Amsterdam</span>
            </p>
            <p>
            <strong>2013-2014</strong><br/>
            BA. International Music Management <br/>
            <span className="subline">Hogeschool InHolland Haarlem</span>
            </p>
            <p><strong>2007-2012</strong><br/>
            HAVO (higher general secondary education)<br/>
            <span className="subline">Martinuscollege</span>
            </p>
          </Panel>
        </div>
        <div className="accordionItem">
          <Panel header="Certifications" collapsible shaded>
            <p>
            <strong>2021</strong><br/>
            Accessibility: How To Design For All <br/>
            <span className="subline">Interaction Design Foundation</span>
            </p>
            <p>
            <strong>2020</strong><br/>
            UX Management: Strategy and Tactics <br/>
            <span className="subline">Interaction Design Foundation</span>
            </p>
            <p>
            <strong>2020</strong><br/>
            Emotional Design<br/>
            <span className="subline">Interaction Design Foundation</span>
            </p>
            <p><strong>2020</strong><br/>
            Psychology of Interaction Design<br/>
            <span className="subline">Interaction Design Foundation</span>
            </p>
            <p><strong>2020</strong><br/>
            Responsive Web Design<br/>
            <span className="subline">freeCodeCamp</span>
            </p>
          </Panel>
        </div>
        <div className="accordionItem">
          <Panel header="Honours" collapsible shaded>
            <p>
            <strong>2021</strong><br/>
            TNW T500 <br/>
            <span className="subline">The Next Web</span>
            </p>
            <p>
            <strong>2018</strong><br/>
            TNW T500 <br/>
            <span className="subline">The Next Web</span>
            </p>
            <p>
            <strong>2015</strong><br/>
            Golden Dot Award Nomination <br/>
            <span className="subline">Hogeschool van Amsterdam</span>
            </p>
          </Panel>
        </div>
          <div className="accordionItem">
          <Panel header="Volunteering" collapsible shaded>
            <p>
            <strong>2019 - current</strong><br/>
            Werkgroep Creatief <br/>
            <span className="subline">Bits of Freedom</span>
            </p>
          </Panel>
        </div>
      </div>
    </div>
  )
}
 
export default About;