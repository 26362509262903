import React from "react"
import { motion } from "framer-motion";
import { Button } from 'rsuite';

function Workplaces(props) {
    return (
      <div className="Videolad">
          <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <section className="hero">
            <img src="./img/Workplaces.jpeg" alt="The Workplaces app in a mock-up" className="caseHeroImage"/>
            <h1>Building an app for the future of work</h1>
                <ul className="projectMeta">
                    <li>
                        <p><strong>Date</strong></p>
                        <p>June 2020 - current</p>
                    </li>
                    <li>
                        <p><strong>Client</strong></p>
                        <p>Vaxel</p>
                    </li>
                    <li>
                        <p><strong>Role</strong></p>
                        <p>Co-Founder, Designer, Engineer</p>
                    </li>
                    <li>
                        <p><strong>Team</strong></p>
                        <p>Adriaan de Wit</p>
                        <p>Joost Reus</p>
                    </li>
                </ul>
        </section>
        <section className="whatIs">
            <h2>What is Workplaces?</h2>
            <p>Workplaces is a map-based app that is dedicated to let you easily find your ideal workplace. The goal is to map all the places that people can work from and make them searchable based on their properties.</p>
            <img src="./img/workplaces-filters.jpg" alt="RTL's office at the Media Park" className="caseHeroImage"/>
        </section>
        <section className="problem">
            <h2>The Problem</h2>
            <p>When the world 'shut down' in May 2020, and workers could not return to the office, the idea of remote working started to become a much more comfortable one for many people.</p>
            <img src="./img/remote.png" alt="Several images and graphs that highlight that remote working is here to stay" className="caseHeroImage"/>
            <p>
            After a while however, the downsides of working remotely started to become painfully clear. From children around the house to neighbours that would not stop drilling in their walls, many people were longing for a place to go in the morning.
            <img src="./img/remote-downsides.png" alt="Several images and graphs that highlight the downsides of working remote" className="caseHeroImage"/>
            </p>
        </section>
        <section className="appTeam">
            <h2>Our solution</h2>
            <p>We foresee a future in which hybrid working will be the status quo. Workers won't need big office buildings or a dull commute to the same place everyday. Somedays people will want to get together, somedays people will want to focus. Most likely in different places that satisfy their current needs.</p>
            <br/>
            <p>We want to create the solution that allows people to do this, mapping all the available places to work or study from, in one big database - and providing acces through a simple, clear and easy to use interface.</p>
        </section>
        <section className="mvp">
            <h2>Minimum Viable Product</h2>
            <img src="./img/wptimeline.png" alt="The timeline of the Workplaces app delivery" className="caseHeroImage"/>
            <p>We set out to build an MVP that could facilitate validating the market need and gather feedback in real user context. For this we launched the app to the App Store and Google Play Store in order to have a low barrier for prospect users to get involved. </p>
            <br/>
            <p>
            Currently we are working on gathering feedback and expanding the MVP by adding more workplace data. We are planning the future of this project and how we can expand and move forward.
            </p>
            <Button className="inPage" size="lg" block href="http://workplaces.io/">Visit the website</Button>
        </section>
        </motion.div>
      </div>
    )
  }
   
  export default Workplaces;