import React from "react"
import { Panel } from 'rsuite';
import { motion } from "framer-motion";

function Music(props) {
    return (
      <div className="Music">
        <motion.div 
        initial={{ opacity: 0,
                   y:100
        }}
        animate={{ opacity: 1,
                   y:0
        }}
        transition={{
          delayChildren: 0.3,
          staggerChildren: 0.2,
          type: "spring",
          stiffness: 260,
          damping: 40
        }} >
        <div className="mainCopyArea">
          <h1>Music</h1>
          <p>
          I've been producing electronic music for over 10 years.<br />
          Several releases, clubs and festivals, radio airtime, starting a recordlabel and lots of learning.
          </p>
        </div>

        
        <section className="JoshuaSphinx">
            <h3>Joshua Sphinx</h3>
            <img src="./img/joshuasphinx.png" alt="Joshua Sphinx performing in front of a crowd" className="caseHeroImage"/>

            <p>
            When I started out DJ'ing and making music back in 2009 I created an alias called Joshua Sphinx. This was the name under which I released all of my music. I had the honour to DJ alongside some pretty big names in the scene at the time, and at some really nice places. I had airtime on SLAM! which is one of the biggest national radiostations in the Netherlands.
            </p>
            <img src="./img/joshuasphinx2.png" alt="Joshua Sphinx performing in front of a crowd" className="mockup rounded" width="100%" height="100%"/>
            <p>
            I devoted a lot of time and money to accumulating gear and knowledge about recording, signal processing, mixing, mastering, (modular)synthesis, composition, but also about studio acoustics, DJ'ing and live performing and learning about the music business.
            </p>
            <p>
            In fact, all of this motivated me to take on a bachelor study International Music Management at Inholland Haarlem in 2013, that I later quit in favor of pursuing a career in design.
            </p>
            <div className="spotifyembed">
                <iframe src="https://open.spotify.com/embed/artist/1vdhcgckccLvI7qbKNjf1z" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
        </section>

        <section className="NeatMachine">
            <h3>Neat Machine</h3>
            <img src="./img/neatmachine.jpg" alt="Neat Machine in the studio" className="mockup rounded" width="100%" height="100%"/>
            <p>In 2016 I wanted to do some more experimenting musically and decided that I wanted to create a new alias to release new and different music under. I decided to go with 'Neat Machine', which, besides sounding nice, works as a wordplay in Dutch. This alias is based around the idea of not taking yourself too seriously, and playing with the boundaries of genres.
            </p>
            <img src="./img/neatmachine2.jpg" alt="Neat Machine performing at a festival" className="mockup rounded" width="100%" height="100%"/>
            <p>After a lot of experimentation and the beginnings of the creation of an album, I released a remix and performed some original music at a festival as Neat Machine.</p>
            <p>It was a difficult process to find the musical direction that I wanted to take with Neat Machine, and to a certain extend I'm still figuring that out today.</p>
            <br />
            <div className="soundcloudembed">
            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/344272507&color=%230f100f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
            <p>More to come.</p>
        </section>
        </motion.div>
    </div>
    )
  }
   
  export default Music;