import React, { useState } from "react";
import styled from "styled-components";
import { Button } from 'rsuite';

const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2em 0 2em 0;
  margin: 0 5% 0 5%;

  @media (min-width: 1250px) {
    max-width: 1250px;
    margin: auto ;
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 0 0 20px;

  li:not(:last-child) {
    margin: 0 15px;
  }

  li:last-child{
    margin: 0 0 0 15px;
  }

  li {
    font-family: "Circular Std", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  @media (max-width: 848px) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: ${props => (props.open ? "91vh" : 0)};
  opacity: ${props => (props.open ? "100%" : 0)};
  z-index: ${props => (props.open ? "2" : "-1")};
  width: 100vw;
  background: rgba(0,0,0,0.75);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transition: opacity .3s ease-in-out, height .3s ease-in-out, z-index .3s ease-in-out;

  @media (min-width: 848px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  list-style: none;
  position: absolute;
  left: 50%;
  top: 240px;
  transform: translate(-50%, -50%);
  width: 80%;
  

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 1.2em 0px;
    transition: opacity 0.7s ease-in-out;
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 15%);
    border-radius: 15px;
    font-family: "Circular Std", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; 
  }
`;
 
const Header = () => {
	const [toggle, toggleNav] = useState(false);
	return (
    <div>
        <Nav>
        	<a className="joost" href="/"></a>
			<Menu>
					<li><Button size="lg" block href="/">Work</Button></li>
					<li><Button size="lg" block href="/about">About</Button></li>
					<li><Button size="lg" block href="/writing">Writing</Button></li>
					{/* <li><Button size="lg" block href="/art">Art</Button></li> */}
					<li><Button size="lg" block href="/music">Music</Button></li>
			</Menu>
			<Button className="menuButton" size="lg" onClick={() => toggleNav(!toggle)} open={toggle}>Menu</Button>
        </Nav>
		<Overlay open={toggle}>
			<OverlayMenu open={toggle}>
				<li><Button size="lg" block href="/">Work</Button></li>
				<li><Button size="lg" block href="/about">About</Button></li>
				<li><Button size="lg" block href="/writing">Writing</Button></li>
				{/* <li><Button size="lg" block href="/art">Art</Button></li> */}
				<li><Button size="lg" block href="/music">Music</Button></li>
			</OverlayMenu>
		</Overlay>
    </div>
  )
}
 
export default Header;